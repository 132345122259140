import React from 'react';
import SVG from '../SVG';
import Styled from './index.styled';

export type CheckboxProps = {
	id: string
	label: string
	name: string
	checkboxes: string[]
};

const FormCheckbox = (props: CheckboxProps) => {
	const { id, label, name, checkboxes } = props;

	const renderCheckbox = (checkbox: string) => (
		<Styled.Checkbox__Wrapper key={`${id}-${checkbox}`}>
			<Styled.Checkbox__Input
				type="checkbox"
				name={name}
				value={checkbox}
				id={checkbox}
			/>

			<Styled.Checkbox__Label as="label" htmlFor={checkbox}>
				<Styled.Checkbox>
					<SVG name="tick" />
				</Styled.Checkbox>
				{checkbox}
			</Styled.Checkbox__Label>
		</Styled.Checkbox__Wrapper>
	);

	return (
		<>
			<Styled.Checkbox__Title as="span">
				{label}
				<Styled.Checkbox__TitleLight as="span">
					Select all that apply.
				</Styled.Checkbox__TitleLight>
			</Styled.Checkbox__Title>
			<Styled.Checkbox__Container>
				{checkboxes.map(renderCheckbox)}
			</Styled.Checkbox__Container>
		</>
	);
};

export default FormCheckbox;
