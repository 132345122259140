import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PhoneSVG from '../../../svgs/UI/Phone';
import { StyledLinkRulertelInline, StyledLinkRulertelInline__link } from './index.styled';

const Link = props => {
	const theme = useContext(ThemeContext);

	return (
		<StyledLinkRulertelInline {...props}>
			<PhoneSVG fill={theme.primaryForeground} />

			<StyledLinkRulertelInline__link as="a" href="tel:08007310300" className="rulertel">
				0800 731 0300
			</StyledLinkRulertelInline__link>
		</StyledLinkRulertelInline>
	);
};

export default Link;
