import React from 'react';
import Styled from './index.styled';
import Browser from '../../../lib/browser';

type LinkProps = {
	url: string;
	dataGACategory?: string;
	dataGAAction?: string;
	dataGALabel?: string;
	target?: string;
	title: string;
};

const InlineLink = ({
	url,
	dataGACategory,
	dataGAAction,
	dataGALabel,
	target,
	title,
}: LinkProps) => (
	<Styled.InlineLink
		href={url}
		data-ga-action={dataGAAction}
		data-ga-category={dataGACategory}
		data-ga-label={dataGALabel}
		target={target}
		onClick={event => Browser.handleClick(event, url)}
		rel="noopener noreferrer"
		as="a"
	>
		{title}
	</Styled.InlineLink>
);

export default InlineLink;
