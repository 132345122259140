import styled, { css } from 'styled-components';
import { inputStatusEnum } from '../../../lib/validation';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { xsBodyBook, xsBodyRegular, smBodyBook, smBodyRegular } from '../Typography/index.styled';

type InputProps = {
	inputStatus: number
};

const select = styled.div`
	margin-bottom: ${gap[24]};

	${above(breakpoints[600], () => css`
		margin-bottom: ${gap[40]};
	`)}
`;

const select__label = styled(smBodyRegular)`
	display: block;
	margin-bottom: ${gap[8]};
	color: var(--black);
`;

const select__optional = styled(xsBodyBook)``;

const select__optionContainer = styled.div`
	position: relative;
`;

const select__select = styled(smBodyBook)<InputProps>`

	display: block;
	width: 100%;
	padding: ${gap[8]} ${gap[52]} ${gap[8]} ${gap[16]};
	border-radius: 4px;
	border: 1px solid var(--grey30);
	outline: none;
	appearance: none;

	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fillRule='evenodd' clipRule='evenodd' d='M7.41 8.59003L12 13.17L16.59 8.59003L18 10L12 16L6 10L7.41 8.59003Z' fill='black' /></svg>") no-repeat;
	background-position: calc(100% - 20px) calc(50%);
	background-color: var(--white);

	&:focus {
		box-shadow: 0px 0px 0px 2px var(--grey15);
	}

	${props => props.inputStatus === inputStatusEnum.ERROR && `
		background-color: var(--errorBackground);
		box-shadow: 0px 0px 0px 2px var(--errorForeground);

		&:focus {
			box-shadow: 0px 0px 0px 2px var(--errorForeground);
		}
	`}

	${props => props.inputStatus === inputStatusEnum.VALID && `
		background-color: var(--validBackground);
		box-shadow: 0px 0px 0px 2px var(--validForeground);

		&:focus {
			box-shadow: 0px 0px 0px 2px var(--validForeground);
		}
	`}
`;

const select__option = styled.option``;

const select__icon = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	right: 18px;
	transform: translate(0, -50%);
	overflow: hidden;
`;

const select__message = styled(xsBodyRegular)`
	color: var(--errorForeground);
	margin-top: ${gap[8]};
`;

export default {
	select,
	select__label,
	select__optional,
	select__optionContainer,
	select__select,
	select__option,
	select__icon,
	select__message
};
