import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, hover } from '../../../styles/tools/media';
import { smBodyRegular } from '../Typography/index.styled';


export const StyledLinkRulertelInline = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	${props => props.withDivider && css`
		padding-bottom: ${gap[32]};
	`}

	& > svg {
		width: 18px;
		height: 18px;
	}

	${props => props.withDivider && css`
		border-bottom: 2px solid var(--primaryBackground);
	`}

	${above(breakpoints[600], () => css`
		& > svg {
			width: 24px;
			height: 24px;
		}

		${props => props.withDivider && css`
			padding-bottom: ${gap[48]};
		`}
	`)}
`;

export const StyledLinkRulertelInline__link = styled(smBodyRegular)`
	position: relative;
	margin-left: ${gap[12]};
	color: var(--black);
	text-decoration: none;

	${hover(() => css`
		cursor: pointer;
		position: relative;
		transition: color 0.3s ease-in-out;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 2px;
			background-color: var(--primaryAccent);
			transition: width 0.3s ease-in-out;
		}

		&:hover,
		&:active,
		&:focus {
			color: var(--primaryForeground);

			&::before {
				width: 100%;
			}
		}
	`)}
`;
