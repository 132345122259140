/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import { inputStatusEnum, validateInput } from '../../../lib/validation';
import Styled from './index.styled';
import SVG from '../SVG';

export type TextareaProps = {
	id: string,
	label: string,
	name: string,
	required?: boolean,
	message?: string
};

const FormTextarea = (props: TextareaProps) => {
	const { name, label, required, message } = props;
	const [ inputStatus, setInputStatus ] = useState(inputStatusEnum.NONE);
	const [ validationMessage, setValidationMessage ] = useState('');

	const validate = (event: React.FocusEvent<HTMLTextAreaElement>) => {
		const target = event.target as HTMLTextAreaElement;
		const validation = validateInput(target.value, required, null, message);

		setInputStatus(validation.status);
		setValidationMessage(validation.message);

		return validation.status;
	};

	return (
		<Styled.Textarea__Container>
			<Styled.Textarea__Label as="label" htmlFor={name}>
				{label}
				{!required && <Styled.Textarea__Optional as="span"> (optional)</Styled.Textarea__Optional>}
			</Styled.Textarea__Label>

			<Styled.Textarea__Inner>
				<Styled.Textarea
					as="textarea"
					cols={80}
					rows={6}
					name={name}
					id={name}
					inputStatus={inputStatus}
					onBlur={validate}
					required={required}
				/>

				{inputStatus === inputStatusEnum.ERROR && (
					<Styled.Textarea__Icon>
						<SVG name="error" />
					</Styled.Textarea__Icon>
				)}

				{inputStatus === inputStatusEnum.VALID && (
					<Styled.Textarea__Icon>
						<SVG name="tick" />
					</Styled.Textarea__Icon>
				)}
			</Styled.Textarea__Inner>

			{validationMessage && <Styled.Textarea__Message>{validationMessage}</Styled.Textarea__Message>}
		</Styled.Textarea__Container>
	);
};

export default FormTextarea;
