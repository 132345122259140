export const inputStatusEnum = {
	NONE: 0,
	ERROR: 1,
	VALID: 2,
};

export const validateInput = (value, required, pattern, message) => {

	const valueEmpty = value === '';
	const valueRequiredAndEmpty = required && valueEmpty;
	const valueDoesNotMatchPattern = pattern && new RegExp(pattern).test(value) === false;

	if (valueRequiredAndEmpty) {
		return {
			status: inputStatusEnum.ERROR,
			message,
		};
	}

	if (valueEmpty) {
		return {
			status: inputStatusEnum.NONE,
			message: '',
		};
	}

	if (valueDoesNotMatchPattern) {
		return {
			status: inputStatusEnum.ERROR,
			message,
		};
	}

	return {
		status: inputStatusEnum.VALID,
		message: '',
	};
};
