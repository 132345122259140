import styled from 'styled-components';
import { xsBodyRegular } from '../Typography/index.styled';

const InlineLink = styled(xsBodyRegular)`
	color: var(--black);

	&:hover,
	&:active {
		text-decoration: none;
	}
`;

export default {
	InlineLink,
};
