import React, { useState } from 'react';
import { inputStatusEnum, validateInput } from '../../../lib/validation';
import Tick from '../../../svgs/UI/Tick';
import Error from '../../../svgs/UI/Error';
import Styled from './index.styled';

export type InputProps = {
	id: string,
	label: string,
	name: string,
	required?: boolean,
	pattern?: string,
	message?: string
	optionalText?: string
};

const FormInput = (props: InputProps) => {
	const { id, name, label, required, pattern, message, optionalText } = props;
	const [ inputStatus, setInputStatus ] = useState(inputStatusEnum.NONE);
	const [ validationMessage, setValidationMessage ] = useState('');

	const validate = (event: React.FocusEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const validation = validateInput(target.value, required, pattern, message);

		setInputStatus(validation.status);
		setValidationMessage(validation.message);

		return validation.status;
	};

	return (
		<Styled.input>
			<Styled.input__label as="label" htmlFor={id}>
				{label}
				{!required && <Styled.input__optional as="span"> ({optionalText})</Styled.input__optional> }
			</Styled.input__label>

			<Styled.input__inputContainer>
				<Styled.input__input
					as="input"
					type="text"
					name={name}
					id={id}
					inputStatus={inputStatus}
					onBlur={validate}
					pattern={pattern}
					required={required}
				/>

				{inputStatus === inputStatusEnum.ERROR && (
					<Styled.input__icon>
						<Error />
					</Styled.input__icon>
				)}

				{inputStatus === inputStatusEnum.VALID && (
					<Styled.input__icon>
						<Tick />
					</Styled.input__icon>
				)}
			</Styled.input__inputContainer>

			{validationMessage && <Styled.input__message>{validationMessage}</Styled.input__message>}
		</Styled.input>
	);
};

export default FormInput;
