import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyLight, smBodyBook, smBodyRegular } from '../Typography/index.styled';

const Checkbox__Container = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: ${gap[16]};
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			grid-template-columns: repeat(6, 1fr);
			margin-bottom: ${gap[40]};
		`
	)}
`;

const Checkbox__Title = styled(smBodyRegular)`
	display: block;
	margin-bottom: ${gap[16]};
	color: var(--black);

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[24]};
		`
	)}
`;

const Checkbox__TitleLight = styled(smBodyLight)`
	margin-left: ${gap[12]};
`;

const Checkbox__Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	grid-column-end: span 2;
`;

const Checkbox__Input = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`;

const Checkbox__Label = styled(smBodyBook)`
	position: relative;
	display: block;
	padding-left: ${gap[36]};
	color: var(--black);
	text-transform: capitalize;

	${Checkbox__Input}:focus + &,
	${Checkbox__Input}:checked + & {
		color: var(--primaryForeground);
	}

	${above(
		breakpoints[960],
		() => css`
			padding-left: ${gap[48]};
		`
	)}
`;

const Checkbox = styled.span`
	position: absolute;
	top: 50%;
	left: 0;
	width: 24px;
	height: 24px;
	padding: 1px;
	border: 1px solid var(--grey30);
	border-radius: 4px;
	color: var(--primaryForeground);
	transform: translateY(-50%);

	${Checkbox__Input}:focus + ${Checkbox__Label} &,
	${Checkbox__Input}:checked + ${Checkbox__Label} & {
		border: 2px solid var(--primaryForeground);
	}

	${Checkbox__Input}:checked + ${Checkbox__Label} & svg {
		display: block;
	}

	svg {
		display: none;
		width: 100%;
		height: 100%;
	}

	svg path {
		fill: currentColor;
	}

	${above(
		breakpoints[960],
		() => css`
			width: 32px;
			height: 32px;
		`
	)}
`;

export default {
	Checkbox__Container,
	Checkbox__Title,
	Checkbox__TitleLight,
	Checkbox__Wrapper,
	Checkbox__Input,
	Checkbox__Label,
	Checkbox,
};
