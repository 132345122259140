import styled, { css } from 'styled-components';
import { inputStatusEnum } from '../../../lib/validation';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { xsBodyBook, xsBodyRegular, smBodyBook, smBodyRegular } from '../Typography/index.styled';

type TextareaProps = {
	inputStatus: number
};

const Textarea__Container = styled.div`
	margin-bottom: ${gap[24]};

	${above(breakpoints[600], () => css`
		margin-bottom: ${gap[40]};
	`)}
`;

const Textarea__Label = styled(smBodyRegular)`
	display: block;
	margin-bottom: ${gap[8]};
	color: var(--black);
`;

const Textarea__Optional = styled(xsBodyBook)``;

const Textarea__Inner = styled.div`
	position: relative;
`;

const Textarea = styled(smBodyBook)<TextareaProps>`
	display: block;
	width: 100%;
	padding: ${gap[8]} ${gap[52]} ${gap[8]} ${gap[16]};
	border-radius: 4px;
	border: 1px solid var(--grey30);
	background-color: var(--white);
	appearance: none;
	outline: none;

	&:focus {
		box-shadow: 0px 0px 0px 2px var(--grey15);
	}

	${props => props.inputStatus === inputStatusEnum.ERROR && `
		background-color: var(--errorBackground);
		box-shadow: 0px 0px 0px 2px var(--errorForeground);

		&:focus {
			box-shadow: 0px 0px 0px 2px var(--errorForeground);
		}
	`}

	${props => props.inputStatus === inputStatusEnum.VALID && `
		background-color: var(--validBackground);
		box-shadow: 0px 0px 0px 2px var(--validForeground);

		&:focus {
			box-shadow: 0px 0px 0px 2px var(--validForeground);
		}
	`}
`;

const Textarea__Icon = styled.div`
	width: 20px;
	height: 20px;
	position: absolute;
	top: 18px;
	right: 18px;
	overflow: hidden;
`;

const Textarea__Message = styled(xsBodyRegular)`
	color: var(--errorForeground);
	margin-top: ${gap[8]};
`;

export default {
	Textarea__Container,
	Textarea__Label,
	Textarea__Optional,
	Textarea__Inner,
	Textarea,
	Textarea__Icon,
	Textarea__Message
};
