import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';

import { RichTextBlock } from './index.styled';

type RichTextProps = {
	content: Document
	center?: boolean
	gdprLink?: boolean
};

const RichText = (props: RichTextProps) => {
	const { content, center, gdprLink } = props;
	return (
		<RichTextBlock as="div" isCenter={center} gdprLink={gdprLink}>
			{documentToReactComponents(content, {
				renderText: text => text.split('\n').flatMap((copy, i) => [i > 0 && <br />, copy])
			})}
		</RichTextBlock>
	);
};

export default RichText;
