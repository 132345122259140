import React from 'react';

import { HiddenInputProps } from '../../../types';

import Styled from './index.styled';

const FormHiddenInput = (props: HiddenInputProps) => {
	const { id, name, value } = props;

	return (
		<Styled.input
			as="input"
			type="hidden"
			name={name}
			id={id}
			value={value}

		/>
	);
};

export default FormHiddenInput;
