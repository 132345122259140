/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { inputStatusEnum } from '@/lib/validation';
import Tick from '../../../svgs/UI/Tick';
import Error from '../../../svgs/UI/Error';
import Styled from './index.styled';

export type DropdownProps = {
	id: string,
	label: string,
	name: string,
	required?: boolean,
	pattern?: string,
	message?: string
	optionalText?: string,
	placeholderMessage?: string,
	options: DropdownOption[]
};

export type DropdownOption = {
	value: string,
	displayText: string
};

const FormDropdown = (props: DropdownProps) => {
	const { id, name, label, required, optionalText, options, placeholderMessage } = props;
	// const [ inputStatus, setInputStatus ] = useState(inputStatusEnum.NONE);
	// const [ validationMessage, setValidationMessage ] = useState('');

	const inputStatus = inputStatusEnum.NONE;
	const validationMessage = '';

	// const validate = (event: React.FocusEvent) => {
	// 	const target = event.target as HTMLInputElement;
	// 	const validation = validateInput(target.value, required, pattern, message);

	// 	setInputStatus(validation.status);
	// 	setValidationMessage(validation.message);

	// 	return validation.status;
	// };

	return (
		<Styled.select>
			<Styled.select__label as="label" htmlFor={id}>
				{label}
				{!required && <Styled.select__optional as="span"> ({optionalText})</Styled.select__optional> }
			</Styled.select__label>

			<Styled.select__optionContainer>

				<Styled.select__select
					inputStatus={inputStatus}
					as="select"
					name={name}
					id={id}
					required={required}
					defaultValue="default">

					<Styled.select__option value="default" disabled hidden />

					{placeholderMessage && placeholderMessage.length > 0 && (
						<Styled.select__option value="default" disabled hidden>
							{placeholderMessage}
						</Styled.select__option>
					)}

					{options.map((dropdownOption, i) => (
							<Styled.select__option value={dropdownOption.value} key={i}>
								{dropdownOption.displayText}
							</Styled.select__option>
						))}

				</Styled.select__select>

				{inputStatus === inputStatusEnum.ERROR && (
					<Styled.select__icon>
						<Error />
					</Styled.select__icon>
				)}

				{inputStatus === inputStatusEnum.VALID && (
					<Styled.select__icon>
						<Tick />
					</Styled.select__icon>
				)}
			</Styled.select__optionContainer>

			{validationMessage && <Styled.select__message>{validationMessage}</Styled.select__message>}
		</Styled.select>

	);
};

export default FormDropdown;
